<template>
  <!-- Fifth and final step in report-flow, path: '/anmeldelser/5' -->
  <div class="card report input-contact-info" role="form">
    <form class="form" @keydown.enter.prevent @submit.prevent>
      <FormTop
        :title="strapiData.title"
        :description="strapiData.description"
      />
      <div class="form-body">
        <label>{{ strapiData.label }}</label>
        <label class="sub-label">{{ strapiData.sub_label }}</label>
        <input
          class="input-no-label"
          style="background-color: var(--lightblue) !important"
          type="text"
          v-model="name"
          :placeholder="strapiData.name_placeholder"
        />

        <div class="telephone-country-code">
          <Vue3IntlInput
            class="input-no-label"
            style="background-color: var(--lightblue) !important"
            ref="contact_number"
            id="contact-number"
            :placeholder="strapiData.phone_placeholder"
            errorMessage="Test"
            v-model="phone"
            @blur="toggleErrorMsg('phone')"
          />
        </div>
        <div
          class="error"
          v-if="phone && showPhoneErrorMsg && !contact_number.isValidNumber"
        >
          {{ strapiData.phone_error_msg }}
        </div>

        <input
          class="input-no-label"
          style="background-color: var(--lightblue) !important"
          type="email"
          v-model="email"
          :placeholder="strapiData.email_placeholder"
          @blur="toggleErrorMsg('email')"
        />
        <div
          class="error"
          v-if="email && showEmailErrorMsg && !isEmailValid(email)"
        >
          {{ strapiData.email_error_msg }}
        </div>
        <label class="mt-5">{{ strapiData.gdpr_label }}</label>
        <div v-html="strapiData.gdpr_sub_label"></div>
        <label>{{ strapiData.when_contacted_label }}</label>
        <label class="sub-label">{{
          strapiData.when_contacted_sub_label
        }}</label>
      </div>
      <div class="form-bottom">
        <div class="d-flex justify-content-center">
          <button
            :class="{
              btn: true,
              'btn-lg': !isWindowSmall,
              'btn-primary': true,
              'my-4': true,
              submit: true,
            }"
            @click="handleSubmit"
            @keyup.enter="handleSubmit"
            :disabled="!isContactInfoValid"
          >
            {{ strapiData.submit_button_text }}
          </button>
        </div>
        <div class="row align-items-center">
          <div class="col">
            <ReportBackBtn />
          </div>
          <div class="col-8 justify-content-center" style="max-width: 450px">
            <StepProgress
              :steps="this.$store.state.report.myStepsProgress"
              :current-step="4"
            />
          </div>
          <div class="col">
            <ReportForwardBtn />
          </div>
        </div>
      </div>
      <ErrorModal />
      <LoadingModal />
    </form>
  </div>
</template>

<script>
import getStrapiData from "@/composables/getStrapiDataSingle";
import ReportBackBtn from "@/components/widgets/ReportBackBtn.vue";
import ReportForwardBtn from "@/components/widgets/ReportForwardBtn.vue";
import axios from "axios";
import StepProgress from "@/components/input-cards/StepProgress.vue";
import FormTop from "@/components/input-cards/FormTop.vue";
import Vue3IntlInput from "@/components/Vue3IntlInput.vue";
import { ref } from "vue";
import ErrorModal from "@/components/input-cards/ErrorModal.vue";
import LoadingModal from "@/components/input-cards/LoadingModal.vue";
import * as bootstrap from "bootstrap";
import * as EncryptionLib from "@/composables/encryptionLib.js"

export default {
  components: {
    ReportBackBtn,
    ReportForwardBtn,
    StepProgress,
    FormTop,
    Vue3IntlInput,
    ErrorModal,
    LoadingModal,
  },
  data() {
    return {
      showPhoneErrorMsg: false,
      showEmailErrorMsg: false,
      formHeight: null,
      isWindowSmall: false,
    };
  },
  setup() {
    const contact_number = ref("contact_number");
    const { strapiData, error, load } = getStrapiData("input-contact-info");
    load();
    return { strapiData, error, contact_number };
  },
  beforeMount() {
    window.onbeforeunload = function () {
      return true;
    };
  },
  mounted() {
    window.addEventListener("resize", this.evaluateWindowSize);
    this.evaluateWindowSize();
  },
  unmounted() {
    window.removeEventListener("resize", this.evaluateWindowSize);
    window.onbeforeunload = function () {
      return null;
    };
  },
  methods: {
    evaluateWindowSize() {
      this.isWindowSmall = window.innerWidth <= 575.98;
    },
    handleSubmit() {
      if (this.isContactInfoValid) {
        this.submitForm();
      }
    },
    toggleErrorMsg(error) {
      if (error === "phone") {
        !this.contact_number.isValidNumber
          ? (this.showPhoneErrorMsg = true)
          : (this.showPhoneErrorMsg = false);
      } else if (error === "email") {
        !this.isEmailValid(this.email)
          ? (this.showEmailErrorMsg = true)
          : (this.showEmailErrorMsg = false);
      }
    },
    isEmailValid(email) {
      let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return !!pattern.test(email);
    },
    async submitForm() {
      let loadingModal = new bootstrap.Modal(
        document.getElementById("loadingModal"),
        { backdrop: "static", keyboard: false }
      );
      let errorModal = new bootstrap.Modal(
        document.getElementById("errorModal"),
        { backdrop: "static", keyboard: false }
      );

      loadingModal.toggle();

      let formData = new FormData();

      let publicKey = (await axios.get(process.env.VUE_APP_PUBLIC_KEY_API)).data.publicKey;
      const keyObj = EncryptionLib.init(publicKey);

      formData.append("encryptedAesKey", keyObj.encryptedAESKey);
      
      formData.append("productName", EncryptionLib.encryptElementAES(this.$store.state.report.productName, keyObj));

      formData.append("brandName", EncryptionLib.encryptElementAES(this.$store.state.report.brandName, keyObj));

      formData.append("productCategory", EncryptionLib.encryptElementAES(this.$store.state.report.productCategory, keyObj));

      formData.append("isMappedCategoryChosen", EncryptionLib.encryptElementAES(this.$store.state.report.isMappedCategoryChosen, keyObj));

      formData.append("reasonForReporting", EncryptionLib.encryptElementAES(this.$store.state.report.reasonForReporting, keyObj));

      await (async () => {
        for (const [index,element] of this.$store.state.report.reasonFiles.entries()) {
          formData.append("reasonFiles[" + index + "]", element);
        }
      })();

      formData.append("idNumbers",EncryptionLib.encryptElementAES(this.$store.state.report.idNumbers, keyObj));

      await (async () => { 
        for (const [index,element] of this.$store.state.report.idFiles.entries()) {
          formData.append("idFiles[" + index + "]", element);
        }
      })();
      
      formData.append("boughtOnline", EncryptionLib.encryptElementAES(this.$store.state.report.boughtOnline, keyObj));

      formData.append("boughtInStore", EncryptionLib.encryptElementAES(this.$store.state.report.boughtInStore, keyObj));

      formData.append("linkInfos",EncryptionLib.encryptElementAES(this.$store.state.report.linkInfos, keyObj));

      formData.append("storeInfos", EncryptionLib.encryptElementAES(this.$store.state.report.storeInfos, keyObj));

      formData.append("contactInfo", EncryptionLib.encryptElementAES(this.$store.state.report.contactInfo, keyObj));

      // Display the key/value pairs
      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      axios
        .post(process.env.VUE_APP_REPORT_API, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(
            "Response from " + process.env.VUE_APP_REPORT_API,
            response
          );
          loadingModal.hide();
          this.$router
            .push({ name: "InputConfirmation" })
            .then(() => this.$store.dispatch("report/resetState"));
        })
        .catch((error) => {
          console.log("Error from " + process.env.VUE_APP_REPORT_API, error);
          loadingModal.hide();
          errorModal.show();
        });
    },
  },
  computed: {
    isContactInfoValid() {
      if (this.phone && !this.contact_number.isValidNumber) {
        return false;
      } else if (this.email && !this.isEmailValid(this.email)) {
        return false;
      } else {
        return true;
      }
    },
    name: {
      get() {
        return this.$store.state.report.contactInfo.name;
      },
      set(value) {
        this.$store.commit("report/setContactInfoName", value);
      },
    },
    phone: {
      get() {
        return this.$store.state.report.contactInfo.phoneNumber;
      },
      set(value) {
        this.$store.commit("report/setContactInfoPhoneNumber", value);
      },
    },
    email: {
      get() {
        return this.$store.state.report.contactInfo.email;
      },
      set(value) {
        this.$store.commit("report/setContactInfoEmail", value);
      },
    },
  },
};
</script>
